<template>
    <div class="modal" v-if="modelValue" :class="{ 'active' : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Goal Settings</h1>
                    <div class="user_info">
                        <img :src="contact.contact.photo" alt="">
                        <div>
                            <h5>{{ contact.contact.name }}</h5>
                            <h6>{{ contact.contact.email }}</h6>
                        </div>
                    </div>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body" v-if="tracking.category">
                <div v-if="refreshContactGoalLoader" class="loader-box">
                    <quote-loader />
                </div>
                <Form v-else v-slot="{ errors }" ref="progress-goals-form" class="progress-goals-form">
                    <template v-for="(category, c) of tracking.categories" :key="c">
                        <div class="category_label" v-if="category.progresses && category.progresses.filter(p => p.enabled).length">
                            <span>{{ category.title }}</span>
                        </div>
                        <template v-for="(prog, p) of category.progresses" :key="p">
                            <div class="goal_items" v-if="prog.enabled && form[prog.id]">
                                <div class="goal_title">
                                    <img v-if="prog.icon" :src="prog.icon" :alt="prog.title">
                                    <img v-else-if="prog.default_icon" :src="require(`@/assets/images/${prog.default_icon}`)" :alt="prog.title">
                                    {{ prog.title }}
                                </div>
                                <div class="scope_area">
                                    <label :for="`${prog.title}-has-goal-${prog.id}`" class="switch_option capsule_btn py-1">
                                        <h5>Goal?</h5>
                                        <input type="checkbox" :id="`${prog.title}-has-goal-${prog.id}`" :true-value="1" :false-value="0" v-model="form[prog.id].has_goal" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="pt-2" v-if="form[prog.id].has_goal">
                                        <label :for="`${prog.title}-client-override-${prog.id}`" class="checkbox py-2">
                                            <input type="checkbox" :id="`${prog.title}-client-override-${prog.id}`" :true-value="1" :false-value="0" v-model="form[prog.id].can_override_goal" hidden>
                                            <span><i class="fas fa-check"></i></span>
                                            <p>Client can override goal settings</p>
                                        </label>
                                        <div class="setting_wpr" v-if="prog.is_circumference || prog.is_macros || prog.is_vitamins || prog.is_minerals || prog.is_supplements || prog.is_workout || prog.is_zones || prog.is_blood_pressure || prog.is_sleep || prog.is_pain || prog.is_push_up || prog.is_pull_up">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Goal Type</label>
                                                    <div class="field_wpr m-0">
                                                        <multiselect v-model="form[prog.id].type" :options="goalOptions"></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-2" v-for="(goal, g) of prog.settings.goals" :key="g">
                                                <h3 class="sub_header mb-0">Goal for {{ Helper.capitalizeFirstLetter(goal.title.replaceAll('_', ' ')) }}</h3>
                                                <div class="form_grp">
                                                    <div class="group_item" v-if="form[prog.id].type == 'Range'">
                                                        <label class="input_label">Low Limit</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_limit_${prog.id}_${g}`] }">
                                                            <Field autocomplete="off" type="number" :name="`low_limit_${prog.id}_${g}`" v-model="form[prog.id].goals[g].low_limit" :class="{ 'has-error': errors[`low_limit_${prog.id}_${g}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, form[prog.id].goals[g], 'low_limit')" @keydown="handleNumberFieldMaxType($event, form[prog.id].goals[g], 'low_limit')" />
                                                            <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                        </div>
                                                        <ErrorMessage :name="`low_limit_${prog.id}_${g}`" class="text-danger" />
                                                    </div>
                                                    <div class="group_item" v-if="form[prog.id].type == 'Range'">
                                                        <label class="input_label">High Limit</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_limit_${prog.id}_${g}`] }">
                                                            <Field autocomplete="off" type="number" :name="`high_limit_${prog.id}_${g}`" v-model="form[prog.id].goals[g].high_limit" :class="{ 'has-error': errors[`high_limit_${prog.id}_${g}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, form[prog.id].goals[g], 'high_limit')" @keydown="handleNumberFieldMaxType($event, form[prog.id].goals[g], 'high_limit')" />
                                                            <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                        </div>
                                                        <ErrorMessage :name="`high_limit_${prog.id}_${g}`" class="text-danger" />
                                                    </div>
                                                    <div class="group_item" v-if="form[prog.id].type == 'Target'">
                                                        <label class="input_label">Target Type</label>
                                                        <div class="field_wpr m-0">
                                                            <multiselect v-model="form[prog.id].goals[g].target_type" :options="goalTargetOptions"></multiselect>
                                                        </div>
                                                    </div>
                                                    <div class="group_item" v-if="form[prog.id].type == 'Target'">
                                                        <label class="input_label">Target Value</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors[`target_${prog.id}_${g}`] }">
                                                            <Field autocomplete="off" type="number" :name="`target_${prog.id}_${g}`" v-model="form[prog.id].goals[g].target" :class="{ 'has-error': errors[`target_${prog.id}_${g}`] }" :min="0" :max="prog.max_limit" />
                                                            <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ prog.unit }}</span>
                                                        </div>
                                                        <ErrorMessage :name="`target_${prog.id}_${g}`" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="setting_wpr" v-else>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Goal Type</label>
                                                    <div class="field_wpr m-0">
                                                        <multiselect v-model="form[prog.id].type" :options="goalOptions"></multiselect>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item" v-if="form[prog.id].type == 'Range'">
                                                    <label class="input_label">Low Limit</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`low_limit_${prog.id}`] }">
                                                        <Field autocomplete="off" type="number" :name="`low_limit_${prog.id}`" v-model="form[prog.id].low_limit" :class="{ 'has-error': errors[`low_limit_${prog.id}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, prog, 'low_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'low_limit')" />
                                                        <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                    </div>
                                                    <ErrorMessage :name="`low_limit_${prog.id}`" class="text-danger" />
                                                </div>
                                                <div class="group_item" v-if="form[prog.id].type == 'Range'">
                                                    <label class="input_label">High Limit</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`high_limit_${prog.id}`] }">
                                                        <Field autocomplete="off" type="number" :name="`high_limit_${prog.id}`" v-model="form[prog.id].high_limit" :class="{ 'has-error': errors[`high_limit_${prog.id}`] }" :min="0" :max="prog.unit == '%' ? 100 : (prog.unit == 'ratings' ? 10 : prog.max_limit)" @keyup="handleNumberFieldMaxType($event, prog, 'high_limit')" @keydown="handleNumberFieldMaxType($event, prog, 'high_limit')" />
                                                        <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ (prog.unit == 'ratings' || prog.unit == 'Ratings') ? 'Stars' :  prog.unit }}</span>
                                                    </div>
                                                    <ErrorMessage :name="`high_limit_${prog.id}`" class="text-danger" />
                                                </div>
                                                <div class="group_item" v-if="form[prog.id].type == 'Target'">
                                                    <label class="input_label">Target Type</label>
                                                    <div class="field_wpr m-0">
                                                        <multiselect v-model="form[prog.id].target_type" :options="goalTargetOptions"></multiselect>
                                                    </div>
                                                </div>
                                                <div class="group_item" v-if="form[prog.id].type == 'Target'">
                                                    <label class="input_label">Target Value</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors[`target_${prog.id}`] }">
                                                        <Field autocomplete="off" type="number" :name="`target_${prog.id}`" v-model="form[prog.id].target" :class="{ 'has-error': errors[`target_${prog.id}`] }" :min="0" max="999" @keyup="handleNumberFieldMaxType($event, prog, 'target')" @keydown="handleNumberFieldMaxType($event, prog, 'target')" />
                                                        <span class="unit" v-if="prog.unit && prog.unit != 'number' && prog.unit != 'Number'">{{ prog.unit }}</span>
                                                    </div>
                                                    <ErrorMessage :name="`target_${prog.id}`" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                </Form>
            </div>
            <div class="modal_body" v-else>
                <div class="coming-soon">
                    <h3>Coming Soon!</h3>
                </div>
            </div>
            <div class="modal_footer" v-if="!refreshContactGoalLoader">
                <button :disabled="updateContactGoalLoader" type="button" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button :disabled="updateContactGoalLoader" class="btn save_btn" @click="handleSubmit"><i class="fa fa-spin fa-spinner" v-if="updateContactGoalLoader"></i> {{ updateContactGoalLoader ? 'Saving' : 'Save' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Field, Form, ErrorMessage } from 'vee-validate'

import Helper from '@/utils/Helper'

export default {
    name: 'Goal Settings',

    data () {
        return {
            form: [],
            goalOptions: [
                { value: 'Range', label: 'Range' },
                { value: 'Target', label: 'Specific Target' }
            ],
            goalTargetOptions: [
                { value: 'Greater', label: 'Greater Than' },
                { value: 'Less', label: 'Less Than' },
                { value: 'Equal', label: 'Equal To' },
            ],
            Helper,
        }
    },

    props: {
        modelValue: Boolean,
        contact: Object,
        tracking: Object,
    },

    emits: ['update:modelValue'],

    watch: {
        modelValue (val) {
            const vm = this;

            if (val) {
                vm.refreshContactGoal({ tracking: vm.tracking.id, contact: vm.contact.contact_id });
            }
        },

        contactGoals: {
            handler (goals) {
                const vm = this;

                vm.form = JSON.parse(JSON.stringify(goals));
            },
            deep: true,
        }
    },

    components:{
        Form,
        Field,
        ErrorMessage,
    },

    computed: mapState({
        refreshContactGoalLoader: state => state.progressTrackingModule.refreshContactGoalLoader,
        updateContactGoalLoader: state => state.progressTrackingModule.updateContactGoalLoader,
        contactGoals: state => state.progressTrackingModule.contactGoals,
    }),

    methods:{
        ...mapActions({
            refreshContactGoal: 'progressTrackingModule/refreshContactGoal',
            updateContactGoal: 'progressTrackingModule/updateContactGoal',
        }),

        closeModal () {
            const vm = this;
            vm.$emit('update:modelValue', false);
        },

        handleNumberFieldMaxType (e, obj, field) {
            const value = parseInt(obj[field]);
            const min   = parseInt(e.target.min);
            const max   = parseInt(e.target.max);

            if (field.includes('low') && min > value) {
                obj[field] = min;
                e.target.value = min;
                e.preventDefault();
            } else if (field.includes('high') && max < value) {
                obj[field] = max;
                e.target.value = max;
                e.preventDefault();
            }
        },

        handleSubmit () {
            const vm = this;
            const form = vm.$refs['progress-goals-form'];

            form.validate().then((result) => {
                if (result.valid) {
                    const params = {
                                        tracking: vm.tracking.id,
                                        contact: vm.contact.contact_id,
                                        goals: vm.form,
                                        setFieldError: form.setFieldError,
                                    };

                    vm.updateContactGoal(params).then((result) => {
                        if (result) {
                            vm.closeModal();
                        }
                    })
                }
            });
        },
    }
}
</script>

<style scoped>
.goal_items {
    border: 1px solid #e9e9e9;
    border-radius: 6px;
    background: #fff;
    margin-bottom: 30px;
}

.goal_items .goal_title {
    padding: 15px 20px;
    border-radius: 6px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.goal_items .goal_title img {
    max-height: 25px;
    width: auto;
    padding-right: 10px;
}

.scope_area {
    border-top: 1px solid #e9e9e9;
    padding: 20px;
}

.scope_area .field_wpr {
    background: #fff;
    display: flex;
}

.scope_area .field_wpr .unit {
    background: #fbfbfb;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 6px 6px 0;
    height: 50px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 500;
}

.scope_area label.checkbox {
    justify-content: flex-start;
}

.loader-box {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.category_label {
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    padding: 15px 0;
    display: flex;
    justify-content: center;
    position: relative;
}

.category_label:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 15%;
    right: 15%;
    border-bottom: 1px solid #d9d9d9;
    z-index: 0;
}

.category_label span {
    padding: 7px 15px;
    background: #fafafb;
    position: relative;
    z-index: 1;
}

.coming-soon {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.coming-soon h3 {
    font-weight: 500;
}
</style>